import { Box, Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsApp = () => {
  return (
    <Box
      sx={{
        position: 'fixed', // Use 'fixed' instead of 'absolute' for a floating effect
        bottom: 20, // Adjust as necessary
        right: 20, // Adjust as necessary
        zIndex: 1000, // Ensure it floats above other elements
      }}
    >
      <a href="https://grabify.link/05VI7N" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
      <Fab style={{ backgroundColor: '#FFFF', color: '#002970' }} aria-label="add">
          <WhatsAppIcon fontSize="large" />
        </Fab>
      </a>
    </Box>
  );
};

export default WhatsApp;
