// App.js or HomePage.js
import React,{useState } from 'react';
import { ThemeProvider, } from '@mui/material/styles';
import theme from './theme';
import SectionOne from './components/SectionOne';
import SectionTwo from './components/SectionTwo';
import SectionThree from './components/SectionThree';
import SectionFour from './components/SectionFour';
import EnquiryForm from './components/EnquiryForm';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import WhatsApp from './components/WhatsApp';
import CompanyLogoSlider from './components/WorkingSlide'
import { Box,Typography } from '@mui/material';
import Testimonial from './components/Testimonial ';
import CurriculumSection from './components/CurriculumSection';
import Fqa from './components/Fqa';

const HomePage = () => {
  const [refState,setRefState] = useState(false);
  
  return(
  <ThemeProvider theme={theme}>
    <Box
    sx={{
      position: 'relative',
      backgroundColor: '#FFFF',
      // overflowY: 'hidden',
    }}
    >
      {/* <Box
      sx={{
        backgroundColor: 'red',
        height: '50px',
        width: '100%',
        position: 'fixed',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
      >
        <Typography sx={{color: 'white', fontSize: '2rem', fontWeight: 'bolder'}} >Website Under Development</Typography>
      </Box> */}
    <NavBar/>
    <SectionOne setRefState={setRefState}/>
    <SectionTwo refState={refState} setRefState={setRefState}/>
    <SectionThree/>
    <CurriculumSection/>
   <CompanyLogoSlider/>
    <SectionFour refState={refState} setRefState={setRefState} />
   <Testimonial/>
   <Fqa/>
    <WhatsApp/>
    </Box>
  </ThemeProvider>
)};

export default HomePage;

