import React from 'react';
import { Container, Typography, Paper, Box, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper elevation={3} sx={{ p: 3, mt: 3, overflow: 'auto' }}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
                Privacy Policy
            </Typography>
            <Typography paragraph>Your privacy is important to us and it is Learnico’s policy to respect your privacy regarding any pieces of information we may collect from you across our website, https://www.Learnico.in/, and other sites we own and operate. This privacy policy information covers what information is collected, what we do with it, and what you can do about it. You can use this information to make your decisions about your privacy.</Typography>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
                Learnico Information Practices
            </Typography>
            <Typography paragraph>We only retain the gathered information for as long as necessary to provide you with your requested course or service. The information provided on our website is appropriate and the content is subject to change at any time. We are not responsible for any damages arising out of the use of the information maintained on this server.</Typography>
            <Typography paragraph>We assure that all our downloadable training content is virus-free. However, Learnico can accept no liability for damages resulting from virus infection.</Typography>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
                Information Collected by Learnico
            </Typography>
            <Typography paragraph>You can browse our website without registration. We may collect and store the information you voluntarily disclose to us to access our free tests and discussion forums. We do not share your personal information with any third party. We also let you know why we’re collecting it and how it will be handled. We do not collect information about our visitors from email databases, private/public organizations or other bodies.</Typography>
            <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
                <ListItem style={{ padding: 0 }} sx={{ display: 'list-item' }}><Typography paragraph>You have total control over the privacy of your information.</Typography></ListItem>
                <ListItem style={{ padding: 0 }} sx={{ display: 'list-item' }}><Typography paragraph>Your information will never be sold, exchanged or disclosed to any third party for marketing purposes.</Typography></ListItem>
                <ListItem style={{ padding: 0 }} sx={{ display: 'list-item' }}><Typography paragraph>You can decide whether you want to opt-in to receive offers from us.</Typography></ListItem>
            </List>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Email and Text Messages:</span> If you no longer want to receive certain communications from us via email or text message, simply click the “unsubscribe” link in the email or reply STOP (or as otherwise instructed) to the text message. Please note that you cannot unsubscribe from service-related correspondence from us, such as messages relating to your student account transactions or information directly relating to your course.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Free course registered students:</span> If you no longer want to receive certain communications from us via email or text message you can unsubscribe from email by replying to us at support@learnico.in or clicking on the unsubscribe at the bottom of the email.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Push Notifications:</span> You can choose to receive mobile push notifications from Learnico. If you subsequently decide you no longer wish to receive these notifications, you can use your mobile device’s settings functionality to turn them off.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Interest-Based Ads:</span> Interest-based ads are online ads that are tailored to your likely interests based on your use of various apps and websites across the Internet. If you are using a browser, then cookies and web beacons can be used to collect information to help determine your likely interests. If you are using a mobile device, tablet, or streaming media device that includes an advertising identifier, then that identifier can be used to help determine your likely interests.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Toll-free: Direct calls registered students:</span> Any calls directly made to Learnico enquiring about the course it is understood that you have shared the consent of receiving updates & promotional messages with regards to the course on your email ID and mobile number. However, you can unsubscribe from email by replying to us at support@learnico.in or clicking on the unsubscribe at the bottom of the email.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>WhatsApp registered students:</span> For any enquiry shared with Learnico through WhatsApp, it is agreed that you have shared the consent to receive updates & promotional messages with regards to the course on your email ID and mobile number. However, you can unsubscribe from email by replying to us at support@learnico.in or clicking on the unsubscribe at the bottom of the email.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Live Chat registered students:</span> Any enquiry done through chat will be considered as consent shared from you to receive updates & promotional messages related to your course. You can unsubscribe to the email by replying to us at support@learnico.in or clicking on the unsubscribe at the bottom of the email. You can also reply STOP to the text message that you have received or click the unsubscribe button to stop receiving the messages.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Careers:</span> The sensitive data of the applicant would be stored very securely. The data shared by the applicant will only be used for internal purpose and in making hiring decisions. Learnico will not share this data with any external sources. The decision of reaching out to an applicant lies solely in the decision of the HR of Learnico. This decision would be made considering the attributes of an applicant</Typography>
            <Typography paragraph>We would need to update this as an additional paragraph in the privacy policy and would need to remove the highlighted line in the screenshot
            </Typography>
            <Typography paragraph>When you purchase our online courses or register for classroom training, you need to make the payment online, thus you will be obligated to share your personal information such as name, email address, telephone number, address(s), credit card number, expiration, and CVV number with us. During this program, we store your contact information in our registration and order-entry systems. Your credit card information is never stored in our system as it is processed by our payment gateway which uses Secure Encryption Technology (SSL). Our payment processing partners are CC Avenue, PayPal and Google Checkout.</Typography>
            <Typography paragraph>Notwithstanding User’s registration with the National Do Not Call Registry (In Fully or Partly blocked category under the National Customer Preference Register set up under the Telecom Regulatory Authority of India), User hereby expresses his interest and accords its willful consent to receive communication (including commercial communication) in relation to company name. User further confirms that any communication, as mentioned herein above, shall not be construed as Unsolicited Commercial Communication under the TRAI guidelines and User has specifically opted to receive communication in this regard on the telephone number provided by the User.</Typography>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
                Cookies Policy
            </Typography>
            <Typography paragraph>To enhance your online experience and track website performance, our website uses cookies. It’s a small text file which gets placed in your computer hard drive and can be retrieved later. Cookies do not tell us who you are.</Typography>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
                Where We Use Cookies
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Account related cookies:</span>Once you create an account with us, then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out. However, in some cases, they may afterwards to remember your site preferences when logged out.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Login related cookies:</span>
                We use cookies when you are logged in so that we can remember this fact and prevent you from having to log in every single time you visit a new page. These cookies are removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Email newsletters-related cookies:</span>
                Our site offers a newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Forms related cookies:</span>
                When you submit your personal information through a form such as those found on the home page or comment forms cookies may be set to remember your user details for future correspondence.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Site preferences cookies:</span>
                In order to provide you with a great experience on this website, we provide the functionality to set your preferences for how this website runs when you use it. In order to remember your preferences, we need to set cookies so that this information can be called whenever you interact with a page that is affected by your preferences.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>E-commerce Tracking:</span>
                To provide a superior online shopping experience, our shopping cart may use cookies to temporarily store names and email addresses. Cookies will never store credit card information as they’re processed using Secure Encryption Technology (SSL) using payment gateways.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>External Links:</span>
                Our website may have links to other external sites which may or may not include third-party websites or resources. We are not responsible or liable in any manner for their privacy policies and information practices. Please read the privacy policies of those third-party websites as every website has different terms of use and privacy policies. We don’t share any personally identifiable information publicly or with third parties, except when required by law.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Course Content:</span>
                Course content for all the study programs both online and classroom programs are provided for the sole purpose of education and guidance only. The course content is regularly reviewed and is subject to change without notice. Learnico reserves the right to modify training content without notice.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Copyright Information:</span>
                You can electronically copy or take print out of the web pages if it is being used for personal use only. Learnico holds the copyright to all the material on this website unless otherwise indicated. Written permission of the copyright holder must be obtained for any use of this material other than for purposes permitted by law.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Personal Information Policy - Image and Video Capture:</span>
                Any images or videos captured during events organized and hosted by Learnico should strictly not be misused. That includes pictures of Learnico staff and training participants. If you enroll in an Learnico course your picture may also be featured in images or videos of the Learnico classes.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Sharing Your Personal Information:</span>
                If you have purchased a Learnico course, we may ask you for a testimonial and if and only when you authorize us, we will display your testimonials on our website, as photos and videos or on our social media channels such as Facebook, YouTube, Google+, Linkedin and Twitter. You should be aware that your publicly identifiable information could be used to send you promotional, unsolicited messages. We are not responsible in any way for your personal information which you have chosen to display.
            </Typography>
            <Typography paragraph>If you do not want us to feature your pictures/testimonials on our website or our social media channels, you can write a mail to enquiry@learnico.in.</Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Personal Information Corrections:</span>
                If you notice that the information we are holding is incorrect or incomplete you have the right to inform us right away. Please drop a mail at enquiry@learnico.in.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Changes to Privacy Policy:</span>
                This privacy policy terms may change at any moment and we reserve the sole right and authority to modify the terms of this privacy policy as the situation demands. When we update the privacy policy, all our registered users will be notified via email.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Deleting Personal Information:</span>
                If you for some reason believe that your personal information should not feature on our website and social media channels and would like to delete your account, you can write to us at enquiry@learnico.in.
            </Typography>
            <Typography paragraph><span noWrap style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Privacy Policy Questions:</span>
                In the event that you have any inquiries concerning how we handle your information and individual data, don't hesitate to get in touch with us. Please mail us at enquiry@learnico.in.
            </Typography>
        </Paper>
    </Container>
);

export default PrivacyPolicy;
