// Banner.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, TextField, Button, CircularProgress, FormLabel, Snackbar, Alert } from '@mui/material';
import moment from 'moment';
import { PiCertificateLight } from "react-icons/pi";
import { PiBag } from "react-icons/pi";
import { RiLiveLine } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { TbMoneybag } from "react-icons/tb";
import { SlCamrecorder } from "react-icons/sl";
import { SlSocialLinkedin } from "react-icons/sl";


const SectionTwo = (props) => {
  const { refState, setRefState } = props;
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Can be 'error', 'warning', 'info', 'success'
  const tagRef = {};
  const [errors, setErrors] = useState({ name: '', mobile: '' });

  useEffect(() => {
    if (tagRef.name && refState) {
      tagRef.name.scrollIntoView({
        top: 0,
        left: 0,
        behaviour: 'smooth'
      })
    }
    setRefState(false)
  }, [refState])



  // Function to validate name - assuming name should be non-empty and letters only
  const validateName = (name) => {
    if (!name) return "Name is required";
    // if (!/^[a-zA-Z\s]+$/.test(name)) return "Name must contain only letters";
    return "";
  };

  // Validate mobile number to be exactly 10 digits (without counting the "+91" prefix)
  const validateMobile = (mobile) => {
    if (!mobile) return "Mobile number is required";
    if (!/^\d{10}$/.test(mobile)) return "Mobile number must be exactly 10 digits";
    return "";
  };


  // Handle input change and validate
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setErrors({ ...errors, name: validateName(value) });
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    // Allow only digits input
    const formattedValue = value.replace(/[^\d]/g, '');
    if (formattedValue.length <= 10) {
      setMobile(formattedValue);
      setErrors({ ...errors, mobile: validateMobile(formattedValue) });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate fields before submitting
    const nameError = validateName(name); // Add name validation logic if needed
    const mobileError = validateMobile(mobile);

    if (nameError || mobileError) {
      setErrors({ name: nameError, mobile: mobileError });
      return;
    }
    setLoading(true); // Start loading
    const formData = { name, mobile, email};

    // Replace '<YOUR_WEB_APP_URL_HERE>' with your actual Web App URL
    const webAppUrl = 'https://script.google.com/macros/s/AKfycbxT7UO7PNAVfin0pCRBf_gC0k46I4RjJHSJZYuFIoVsiRV3Lj0n0m-TF7lRokUG0YUL/exec';

    fetch(webAppUrl, {
      method: 'POST',
      mode: 'no-cors', // Important for avoiding CORS issues
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(() => {
        setLoading(false); // Stop loading
        setSnackbarMessage('Form submitted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true); // Show success snackbar
        setName('');
        setMobile('');
        setEmail('');
      })
      .catch(error => {
        setLoading(false); // Stop loading
        console.error('Error:', error);
        setSnackbarMessage('Failed to submit the form');
        setSnackbarSeverity('error');
        setSnackbarOpen(true); // Show error snackbar
      });
  };

  const FeatureGridItem = ({ icon: Icon, title, description }) => (
    <Box
      sx={{
        padding: 3.5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center items horizontally in the column
        justifyContent: 'center', // Center items vertically
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: { xs: 25, md: 75 },
        }}
      >
        <Icon style={{ color: '#ffc66f', fontSize: 'inherit' }} />
      </Box>
      <Typography
        // variant="subtitle1"
        sx={{
          textAlign: 'center',
          fontSize: { xs: '16px', md: '22px' },
          fontWeight: 700,
          // paddingY: 1,
        }}
      >
        {title}
      </Typography>
      <Typography
        // variant="body2"
        sx={{
          textAlign: 'center',
          fontSize: { xs: '12px', md: '16px' },
          fontWeight: 400,
          // maxWidth: '200px'
        }}
      >
        {description}
      </Typography>
    </Box>
  );

  return (
    <Grid container
      justifyItems='center'
      justifyContent='center'
      mt={10}
    >
      <Grid item container xs={12} md={10} spacing={2}>
        <Grid xs={12} />
        <Grid item container xs={12} spacing={2} justifyContent='center' >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: '#f8f9f9',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Grid container xs={12} direction='row' justifyContent='center' alignItems='center'>
                <Grid item container xs={6} display='flex' flexDirection='column'>
                  <Grid item xs={12}><FeatureGridItem icon={PiCertificateLight} title="Google Certification" description="Recognized Certification" /></Grid>
                  <Grid item xs={12}><FeatureGridItem icon={PiBag} title="Placement" description="100% Job guaranteed" /></Grid>
                  <Grid item xs={12}> <FeatureGridItem icon={RiLiveLine} title="Live Sessions" description="Instant doubt clarification" /></Grid>
                </Grid>
                <Grid item container xs={6} display='flex' flexDirection='column'>
                  <Grid item xs={12}><FeatureGridItem icon={SlSocialLinkedin} title="Profile" description="LinkedIn & Resume Optimization" /></Grid>
                  <Grid item xs={12}><FeatureGridItem icon={TbMoneybag} title="Salary" description="Get placed at Avg. 7LPA - 18LPA" /></Grid>
                  <Grid item xs={12}><FeatureGridItem icon={SlCamrecorder} title="Recorded Videos" description="Learn at your own pace" /></Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} ref={(ref) => {
            tagRef.name = ref
          }}>
            <Paper elevation={3} sx={{
              width: { md: '100%' },
              borderRadius: 5,
              margin: { xs: 2, md: 3 }, // Adjust margin for mobile
            }}>
              <Grid item container sx={{
                padding: { xs: 2, md: 5 }
              }} spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: 24
                    }}
                  >
                    Start Your Journey to <span style={{ color: '#ffc66f' }}>Web Dev Mastery!</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel required>Name</FormLabel>
                  <TextField
                    fullWidth
                    required
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    value={name}
                    onChange={handleNameChange}
                  // ... rest of your TextField props
                  />
                </Grid>
                <Grid item xs={12}>
                <FormLabel required>Mobile</FormLabel>
        <TextField
          fullWidth
          required
          value={mobile}
          onChange={handleMobileChange}
          error={Boolean(errors.mobile)}
          helperText={errors.mobile}
          InputProps={{
            startAdornment: <Typography style={{marginRight: 5}} >+91</Typography>, // Display "+91" as a static prefix
          }}
        />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel sx={{ mb: 1 }}>Email ID</FormLabel>
                  <TextField
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                      mt: 1,
                      '& .MuiInputBase-input': { // Targeting the input element for height adjustment
                        height: '50px', // Example height, adjust as needed
                        padding: '0 14px', // Adjust padding to vertically center text, adjust as needed
                      },
                      '& .MuiOutlinedInput-root': { // Adjusting the height of the TextField border
                        alignItems: 'center',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 10
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={(e)=> handleSubmit(e)}
                    disabled={loading} // Disable button when loading
                    fullWidth
                    sx={{
                      backgroundColor: loading ? '#FFFF' : '#ffbd59', // Optional: change background color when loading
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'relative', // For positioning the CircularProgress
                      borderRadius: 10
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{
                        color: '#ffbd59', // Change the color if needed
                      }} />
                    ) : (
                      "Enroll Now"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#333', // Custom background color for Snackbar
            color: 'white', // Custom text color
          }
        }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{
            width: '100%',
            backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336', // Example: green for success, red for error
            color: 'white', // Custom text color for the Alert
            '.MuiAlert-icon': { // Targeting the icon inside the Alert
              color: 'white', // Ensuring the icon matches the text color
            }
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
export default SectionTwo;