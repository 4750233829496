import React from 'react';
import { Box, Typography, Grid, Paper, IconButton } from '@mui/material';
import { BsGraphUpArrow } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi2";
import Cognizant from "../../src/asset/cognizant_logo.png"
import TCS from "../../src/asset/tcs_logo.png"
import Tech from "../../src/asset/tech_logo.png"
import INFY from "../../src/asset/INFY_logo.png"
import Accenture from "../../src/asset/accenture_logo.png"
import Wipro from "../../src/asset/wipro_logo.png"
import IBM from "../../src/asset/IBM_logo.png"

const CareerOutcomes = () => {
  return (
    <Box sx={{ p: 4, backgroundColor: '#fff9f1', textAlign: 'center' }}>
      <Grid container spacing={4} justifyContent="center" alignItems='center'>
        <Grid item xs={12} md={5}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', mb: 5, textAlign: 'left', fontSize:{xs:'22px', md: '26px'} }}>
              What our learners have achieved?
            </Typography>
          </Grid>
          <Grid item >
            <Paper elevation={3} sx={{ py: 2, borderRadius: 5, }}>
              <Grid container spacing={2}>
                <Grid container spacing={4} justifyContent='space-around' p={3}>
                  <Grid item xs={6} md={5} display='flex' direction='row' alignItems='center'>
                    <Box mr={2} style={{ backgroundColor: '#fdf2de', padding: 15, borderRadius: 50 }} >
                     <Box sx={{fontSize : {xs : 15, md: 35}}}>
                     <BsGraphUpArrow style={{ color: '#f5a623' }} />
                     </Box>
                    </Box>
                    <Grid display='flex' direction='column'>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#f5a623', textAlign: 'left', fontSize:{xs: '12px', md: '18px'} }}>80%</Typography>
                      <Typography sx={{ textAlign: 'left', fontSize:{xs: '8px', md: '16px'} }} >Increase your chance of getting hired</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={5} display='flex' direction='row' alignItems='center'>
                    <Box mr={2} style={{ backgroundColor: '#fdf2de', padding: 15, borderRadius: 50 }} >
                     <Box sx={{fontSize : {xs : 15, md: 35}}}>
                     <BiTransfer style={{ color: '#f5a623' }} />
                     </Box>
                    </Box>
                    <Grid display='flex' direction='column'>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#f5a623', textAlign: 'left', fontSize:{xs: '12px', md: '18px'} }}>100+</Typography>
                      <Typography sx={{ textAlign: 'left', fontSize:{xs: '8px', md: '16px'} }} >Students got learned from the expert</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={4} justifyContent='space-around' p={3}>
                  <Grid item xs={6} md={5} display='flex' direction='row' alignItems='center'>
                    <Box mr={2} style={{ backgroundColor: '#fdf2de', padding: 15, borderRadius: 50 }} >
                     <Box sx={{fontSize : {xs : 15, md: 35}}}>
                     <BsGraphUpArrow style={{ color: '#f5a623' }} />
                     </Box>
                    </Box>
                    <Grid display='flex' direction='column'>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#f5a623', textAlign: 'left', fontSize:{xs: '12px', md: '18px'} }}>7LPA - 18LPA</Typography>
                      <Typography sx={{ textAlign: 'left', fontSize:{xs: '8px', md: '16px'} }} >Get placed in an higher package</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={5} display='flex' direction='row' alignItems='center'>
                    <Box mr={2} style={{ backgroundColor: '#fdf2de', padding: 15, borderRadius: 50 }} >
                     <Box sx={{fontSize : {xs : 15, md: 35}}}>
                     <HiOutlineUserGroup style={{ color: '#f5a623' }} />
                     </Box>
                    </Box>
                    <Grid display='flex' direction='column'>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#f5a623', textAlign: 'left', fontSize:{xs: '12px', md: '18px'} }}>78+</Typography>
                      <Typography sx={{ textAlign: 'left', fontSize:{xs: '8px', md: '16px'} }} >Hiring Partners</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* Recruiters Section */}
        <Grid item container justifyContent="center" alignItems='flex-start' md={4} direction='column'>
         <Box mt={5}  sx={{ml : {xs : 5 , md : 10}}}>
         <Typography sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left', fontSize:{xs:'18px', md: '24px'} }}>
            Top Recruiters
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-around', alignItems: 'center', }}>
            <Box>
              <img
                src={Cognizant}
                style={{
                  height: 50,
                  width: 75,
                  objectFit: 'contain', 
                }}
              />
            </Box>
            <Box>
              <img
                src={TCS}
                style={{
                  height: 50,
                  width: 75,
                  objectFit: 'contain', 
                }}
              />
            </Box>
            <Box>
              <img
                src='https://www.guvi.in/images/webps/zen_new/chargebee.webp'
                style={{
                  height: 50,
                  width: 75,
                  objectFit: 'contain', 
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-around', alignItems: 'center', }}>
            <Box>
              <img
                src={INFY}
                style={{
                  height: 50,
                  width: 75,
                  objectFit: 'contain', 
                }}
              />
            </Box>
            <Box>
              <img
                src='https://www.guvi.in/images/webps/zen_new/juspay.webp'
                style={{
                  height: 50,
                  width: 75,
                  objectFit: 'contain', 
                }}
              />
            </Box>
            <Box>
              <img
                src='https://www.guvi.in/images/webps/zen_new/orange.webp'
                style={{
                  height: 50,
                  width: 75,
                  objectFit: 'contain', 
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-around', alignItems: 'flex-start', }}>
            <Box>
              <img
                src={IBM}
                style={{
                  height: 50,
                  width: 75,
                  objectFit: 'contain', 
                }}
              />
            </Box>
          </Box>
         </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareerOutcomes;
