import React from 'react';
import { Box, Typography, Grid, Button, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Image from "../../src/asset/Learnico_Kickstarter_Certificate.png"; // Correct the path as needed

const SectionFour = ({ setRefState }) => {
  return (
    <Grid style={{display:'flex',flexDirection:'column',alignItems:'center',marginBottom:'4rem'}}>
    <Grid container justifyContent="center" alignItems="center" spacing={4} sx={{ my: 4 }}>
      <Grid item xs={12}>
        <Typography sx={{ textAlign: 'center', fontSize: { xs: '18px', md: '36px' } }}>
          Enrich Your CV with <span style={{ color: '#f5a623' }}>Global Certifications</span>
        </Typography>
      </Grid>
      <Grid item container xs={12} md={10} sx={{ mx: { xs: 2, md: 0 } }}>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={Image}
            sx={{
              height: { xs: 'auto', md: 350 },
              width: { xs: '100%', md: 550 },
              objectFit: 'contain',
              maxWidth: '100%',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingLeft: '16px', // Adjust padding to align with the rest of your content
            my: { xs: 2, md: 0 }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
            <Box component="span" sx={{ marginRight: '8px', fontSize: 'inherit' }}>•</Box>
            <Typography variant="body1" gutterBottom>
              <strong>Industry Recognition:</strong> Gain industry-recognized credentials that validate your skills and expertise in web development.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
            <Box component="span" sx={{ marginRight: '8px', fontSize: 'inherit' }}>•</Box>
            <Typography variant="body1" gutterBottom>
              <strong>Career Advancement:</strong> Enhance your resume and stand out to employers with a certificate showcasing your commitment to professional development.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
            <Box component="span" sx={{ marginRight: '8px', fontSize: 'inherit' }}>•</Box>
            <Typography variant="body1" gutterBottom>
              <strong>Skill Validation:</strong> Demonstrate your proficiency in key areas of web development, from HTML and CSS to JavaScript and beyond.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
            <Box component="span" sx={{ marginRight: '8px', fontSize: 'inherit' }}>•</Box>
            <Typography variant="body1" gutterBottom>
              <strong>Boost Your Resume:</strong> Enhance your resume with a prestigious certificate, showcasing your dedication to continuous learning and professional growth.
            </Typography>
          </Box>
          {/* LinkedIn sharing and icon can be added here if needed */}
        </Grid>

      </Grid>
    </Grid>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
        <Box sx={{ p: 0 }}>
          <Button variant="contained" sx={{ backgroundColor: '#f5a623', ':hover': { backgroundColor: 'blue' }, padding: '10px 60px 10px 60px', width: { xs: '100%', md: 'auto' } }} onClick={() => {
            setRefState(true)
          }}>
            <Typography textTransform='none' variant="button" sx={{ color: '#FFFF', fontSize: '20px', fontWeight: 600 }}>ENROLL NOW</Typography>
          </Button>
        </Box>
        </Box>
        </Grid>
  );
};

export default SectionFour;
