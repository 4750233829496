import React from 'react';
import { Container, Typography, Paper, Box, List, ListItem, ListItemText } from '@mui/material';

const TermsOfUsePage = () => (
  <>
  <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
    <Paper elevation={3} sx={{ p: 3, mt: 3, overflow: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Terms of Use
      </Typography>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Please read the following carefully
      </Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>These terms and conditions (“Terms and Conditions”) control your use of this website Learnico.in (“Website”). In these Terms and Conditions, “Learnico” is referred to as the “Company”, “us,” or “we.”</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>
            These terms and conditions (“Terms and Conditions”) control your use of this website Learnico.in (“Website”). In these Terms and Conditions, “Learnico” is referred to as the “Company”, “us,” or “we.”
          </ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>
            ‘You’ refers to a user or a paying customer. If you are a company or another person who gives access to company products, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.
          </ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>The Learnico website (the ‘Site’), the educational services made available through the site and the content (the ‘Products’) are owned, operated and maintained, as applicable, by Learnico (‘we’, ‘our’, ‘us’, or the ‘Company’). The Site, Products and Content are, collectively, the ‘Company Products’.</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>By (a) using or accessing the Company Products, including, but not limited to downloading or accessing, (b) offering a Course through the Site or through Software; you agree to the terms and conditions set forth in these Terms of Use (the “Terms”)</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>By using this website or its Products and Services, You Agree and Warrant that you have read, understood, and agree to be bound by these terms. The company’s privacy policy can be found at privacy policy. If you do not accept these terms, you must not use – and are not authorized to use – all or any portion of the company’s website and its products or services (as defined below).</ListItemText>
        </ListItem>
      </List>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Please read them carefully before you use the services of this site.
      </Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>You should not use this site in an unlawful manner; you must respect website terms and conditions and follow the privacy policy.</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>Under no situations or circumstances, the company will be liable for any change in the content that it provides on the website through its products and services, including but not limited to any errors, omissions, loss or damage experienced in connection with the use of exposure, any content made available via our products, services or various resources such as email, blog etc.</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>Our services are free to any user with access to the internet. However, we are not responsible for the charges incurred for the usage of hardware, software or internet services provider fees. Also, the user is fully responsible for the proper functioning of computer hardware and internet access</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>You will be required to use login credentials for some of the sections on the site and the company reserves the right to block access to our services for any user who does not follow these conditions.</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>We make sure that users get uninterrupted access to our service, but there is no obligation to do so.</ListItemText>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <ListItemText>Learnico is not responsible and is not obligated for issues in your network or server beyond certain limits.</ListItemText>
        </ListItem>
      </List>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Website Usage Guidelines
      </Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}>
          <Typography paragraph>
            Do not insult, abuse, harass, stalk, threaten, or otherwise infringe the rights of others; Do not publish, post, distribute or disseminate any defamatory, infringing, indecent, offensive, or unlawful material or information.
          </Typography>
        </ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>Do not publish post, distribute or disseminate any defamatory, infringing, indecent, offensive or unlawful material or information.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>Do not upload, install, or transfer files that are protected by Intellectual Property laws or software which affects other computers.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>It’s prohibited to edit HTML source code, reverse engineer or attempt to hack.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>Do not run Spam services/scripts or anything which could affect infrastructure, and in turn, users.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>Do not communicate spam, advertise or sell services such as digital downloads, eBooks or phishing links.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>You may not copy, distribute or indulge in plagiarism with website content or user-submitted content.</Typography></ListItem>
      </List>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        The content
      </Typography>
      <Typography paragraph>All website content or information that can be seen, heard or otherwise experienced on the Site is copyrighted and belongs to Learnico or its partners, affiliates or third parties. You may use the Site, the Service and the Content for your own personal, non-commercial use only.</Typography>
      <Typography paragraph>You may download and print the available material for your own personal, non-commercial use only.</Typography>
      <Typography paragraph>You will not transfer any information from the website or produce derivative work that you can display, distribute or transmit.</Typography>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Links and Hyperlinks Terms
      </Typography>
      <Typography paragraph>This website may have links to other websites. We do not undertake any control on the content of these websites; nor are we responsible for their website content. The sole purpose of the links included is to provide users with information. Hence, Learnico will not be held responsible.</Typography>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Hyperlinks
      </Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>You may not mirror or frame the home page or any other pages of this Site on any other website or web page.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph></Typography>Do not link to Learnico pages and subpages with spam links/anchor text which could provide a false impression. This may create misunderstandings for the users.</ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph></Typography>Do not use or include copyrighted or registered trademarks, or Intellectual property images, designs or content as a link to the Learnico website.</ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph></Typography>Do not link to pages that support racism, or terrorism.</ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>Do not link to pages that provide pornographic content and violate human rights.</Typography></ListItem>
      </List>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Copyright and Intellectual Property
      </Typography>
      <Typography paragraph>We value and respect others' intellectual property and expect our users to do the same.</Typography>
      <Typography paragraph>The entire contents of the Site are protected by copyright and trademark laws. The owner of the copyrights and trademarks are Learnico.in, its affiliates or other third-party licensors. The material on the site, including text, graphics, code and/or software is copyrighted and belongs to Learnico, therefore you may not duplicate, modify, publish or reproduce the content in any manner.</Typography>
      <Typography paragraph>Learnico does not take any responsibility for the content on other sites (except our partners and affiliates), that you may find when searching or accessing Learnico products or services. The privacy policy and terms of use of the sites that you visit will administer that material.
        Learnico has all the rights to disable or prohibit access to users who do not respect and are involved in the infringement of Learnico's intellectual property.
      </Typography>
      <Typography paragraph>You are not allowed to use any of the digital images or logos from the website. In case of copyright issues, there has to be a written consent from the trademark owner
        <Typography style={{ fontWeight: 'bold' }}>Claims of Intellectual Property Violations</Typography>
      </Typography>
      <Typography paragraph>If you believe that your work has been used without your permission in a way that prompts copyright infringement. Please provide us with the below information and we will act on it.</Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>The authorized person who will act on behalf of the owner of the copyright should send a digital or physical signature</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>A description of the copyrighted work that you claim to be infringing your IP.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph></Typography>A description of where and how the material that you claim is infringing is located on the Learnico website, with enough detail that we may find it on the website.</ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph></Typography>Contact Details – Address, telephone number, and email address.</ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph></Typography>A statement by you, that the information you provided is accurate and your claim of the copyright or intellectual property is on your owner’s behalf</ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph></Typography>You can reach Learnico to notify your claims of copyright By email: enquiry@Learnico.in</ListItem>
      </List>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Transaction Terms
      </Typography>
      <Typography paragraph>If you believe that your work has been used without your permission in a way that prompts copyright infringement. Please provide us with the below information and we will act on it.</Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>To make a transaction on the Learnico website, you are bound to pay for that transaction.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>Please pay close attention to your payment details such as total bill, taxes, shipping costs, and discounts.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>There are certain products that require additional terms and conditions which you have to agree to before you make the purchase.</Typography></ListItem>
      </List>
      <Typography paragraph style={{ textTransform: 'capitalize' }}>WE MAKE NO WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED, WITH RESPECT TO ANY PRODUCTS OR SERVICES SOLD ON OR THROUGH Learnico.</Typography>
      <Typography paragraph>No additional or different terms contained in any purchase order, document, transmission or other communication shall be binding upon Learnico unless agreed to by Learnico in writing.</Typography>
      <Typography paragraph>Learnico reserves the right to modify, or change without prior notice and in its sole discretion, to limit the order quantity on any item and to refuse service to anyone.</Typography>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Pricing Disclaimer
      </Typography>
      <Typography paragraph>All prices, products and offers on the Learnico website are subject to change without notice.
        While we make sure to provide the most accurate and up-to-date information, in some cases one or more items on our website may be priced incorrectly. This might happen due to human errors, digital images, technical errors or a mismatch in pricing information received from our suppliers.
      </Typography>
      <Typography paragraph>Learnico reserves the right to change prices for all our products, offers or deals. These changes are done due to market conditions, course termination, providers, price changes, errors in advertisements and other mitigating circumstances. However, the price you paid at the time of purchase still holds for you.</Typography>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Proprietary Use of Learnico Study Material
      </Typography>
      <Typography paragraph>Learnico reserves the right to change prices for all our products, offers or deals. These changes are done due to market conditions, course termination, providers, price changes, errors in advertisements and other mitigating circumstances. However, the price you paid at the time of purchase still holds for you.</Typography>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
        Careers
      </Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>The company will use job seeker's Information obtained in the course of recruiting activities only for the purposes of screening and decision-making for hiring or personnel management and will not use such information for any other purpose.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>The Company will endeavour to keep job seekers' Information accurate and updated. Also, the Company will take necessary and appropriate security management measures and supervise our respective officers and employees in order to prevent any information leak.</Typography></ListItem>
        <ListItem style={{padding:0}} sx={{ display: 'list-item' }}><Typography paragraph>The company has the right to reach out the aspiring job seekers through any mode of communication at any time.</Typography></ListItem>
      </List>
    </Paper>
  </Container>
  </>
);

export default TermsOfUsePage;
