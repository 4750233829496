import React from 'react'
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button, Grid, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { GetIsMobile } from '../util/general'

function Fqa() {
    const [expanded, setExpanded] = React.useState(false);
    const isMobile = GetIsMobile();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const data = [
        {questions:'What is meant by Full Stack Development Course?',answers:'Full Stack Course breaks down the fundamentals of each technology that you would require to become a Full Stack Developer. The entire course is broken down into five separate segments: Frontend, Backend, Database, Other Essential Technologies and Debugging/Version Control.'},
        // {questions:'What is MEAN and MERN stack?',answers:`${'<ul><li>MEAN - MongoDB, ExpressJs, AngularJs & NodeJs</li><li>MERN - MongoDB, ExpressJs, ReactJs & NodeJs</li></ul>'}`},
        {questions:'Is it 100% online learning or should I come in person for any specific course?',answers:'It is a 100% online learning course package and there won’t be any necessity for you to be present in person.'},
        {questions:'Will I gain access to any sort of Forum support?',answers:'Yes. You will gain complete access to our forum support to connect with our fellow aspiring users. '},
        {questions:'On what basis are the certificates rolled out?',answers:'The certificates are rolled out as and when you complete a course. '},
        {questions:'How will the 10X Growth Web Development Pack help me secure a career?',answers:'A lot of college students and freshers are struggling with a lukewarm placement season and a lack of better skills. Taking up Learnico’s 10X Growth Web Development in Pack will help you gain 10 global skill certifications, which will add well to your resume and help you in your job search.'},
        {questions:'How much time would I need to learn web development completely?',answers:'Web development is a vast domain that involves mastering a lot of frameworks and graphics. With Learnico’s Grow 10X bundle, you can begin your web development learning journey and finish it at your own pace while learning in your own language, ensuring better and more targeted learning. One can easily finish the entire bundle within a month. However, it would take good practice in each module over months to build a solid foundation in all web development tools and languages.'},
        {questions:'What is the capstone Project all about and how will it help me?',answers:'As part of the Capstone Project, the participants are required to build their own application by the end of the course which can be added to their GitHub profile. With an emphasis on learning by doing, the bootcamp course helps participants working on building their application from the 1st week itself. At the end, the participant builds his own application understanding the software development process, code review process, and also learns the best practices in coding and utilization of Cloud & DevOps tools etc.'},
        {questions:'What are the payment options?',answers:'For details regarding the payment reach us at 7695820850.'},
        {questions:'What is the duration of this course?',answers:'4 Months or 100 days (Monday to Friday),'},
        {questions:'Which companies will I be given opportunities for placements?',answers:'We have partnered with over 200+ companies including Paypal, Freshworks,Zoho, Juspay, DBS, Scapic, OrangeScape, etc.'},
        {questions:'I’m from a non-engineering background or New to programming. Am I eligible for this course?',answers:'With the objective of creating as many job opportunities for our students, we do intend to help every student who is willing to “make the extra catching up needed” in terms of programming & Development logic. We assess this via a comprehensive PreBootcamp where you can understand how ready you are for the Bootcamp. In case you don’t make the eligibility,our mentors will charter the course aheads for you with some Learnico Lessons.'},
        {questions:'Will I get a job after completing this program?',answers:'The Full Stack online course we will help you secure your dream job through career tests, one-on-one career counselling calls, mock interviews with software development experts, resume feedback and profile building, and hiring opportunities. This is why ours is the best Full Stack developer course.'},

    ]
    return (
        <Box sx={isMobile ? {textAlign: 'center',display:'flex',alignItems:'center' } : { p: 4, textAlign: 'center' }}>
            <Grid spacing={4}>
                <div style={{ margin: '0 auto', padding: '40px', width: '90%', height: '100%' }}>
                    <h1>Frequently Asked Questions (FAQs)</h1>
                </div>
                <Grid container style={isMobile ? { display:'flex',alignItems:'center',paddingLeft: '1.7rem', paddingRight: '1.7rem' } : { paddingLeft: '15rem', paddingRight: '15rem' }}>
                <Grid container spacing={2} sx={{ mt: 0, mb: 4 }} >
                    {data.map((module, index) => (
                        <Grid item xs={12} key={index} >
                            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} sx={{ border: '1px solid black' }}>
                                <AccordionSummary
                                    expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                >
                                    <Typography><b>{module.questions}</b></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography key={index}>
                                        {module?.answers}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            </Grid>
        </Box>
    )
}

export default Fqa