import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Grid, Typography, IconButton, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => (
  <Box sx={{ background: 'linear-gradient(to right bottom, #002970, #3A6AB9)', color: 'white', mt: 5, py: 3 }}>
    <Container maxWidth="lg">
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
            <li>
              <Link component={RouterLink} to="/terms-and-conditions" sx={{ color: 'inherit', textDecoration: 'none' }}>
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link component={RouterLink} to="/privacy-policy" sx={{ color: 'inherit', textDecoration: 'none' }}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link component={RouterLink} to="/refund-policy" sx={{ color: 'inherit', textDecoration: 'none' }}>
                Cancellation and Refund Policy
              </Link>
            </li>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Follow Us
          </Typography>
           Social Icons updated for accessibility
          <IconButton aria-label="Facebook" component="a" href="https://facebook.com" color="inherit" rel="noopener noreferrer" target="_blank">
            <FacebookIcon />
          </IconButton>
          <IconButton aria-label="Twitter" component="a" href="https://twitter.com" color="inherit" rel="noopener noreferrer" target="_blank">
            <TwitterIcon />
          </IconButton>
          <IconButton aria-label="LinkedIn" component="a" href="https://linkedin.com" color="inherit" rel="noopener noreferrer" target="_blank">
            <LinkedInIcon />
          </IconButton>
          <IconButton aria-label="Instagram" component="a" href="https://instagram.com" color="inherit" rel="noopener noreferrer" target="_blank">
            <InstagramIcon />
          </IconButton>
        </Grid> */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1">Email: <Link href='mailto:support@learnico.in' sx={{ textDecoration: 'none', color: 'inherit' }}>support@learnico.in</Link></Typography>
          <Typography variant="body1">Phone: +91-769 582 0850</Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
        © {new Date().getFullYear()} Learnico By Zeeraa Academy. All rights reserved.
      </Typography>
      <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
        403-C6, AWHO Raman Vihar, Chinavendampatti, saravampatti,Coimbatore, Tamil Nadu - 641049
      </Typography>
    </Container>
  </Box>
);

export default Footer;
