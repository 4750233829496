import React from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button, Grid, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { GetIsMobile } from '../util/general'
import syllabus from '../asset/syllabus.pdf'
import EnquiryForm from './Enquiry';

const CurriculumSection = () => {
    const [expanded, setExpanded] = React.useState(false);
    const [enquiryForm, setEnquiryForm] = React.useState(false);
    const isMobile = GetIsMobile();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClose = () => {
        setEnquiryForm(false);
        
    }

    const handleDownload = () => {
        setEnquiryForm(false);
        const link = document.createElement('a');
        link.href = syllabus;
        link.download = 'syllabus.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
         // Assuming this is to close the modal or form
    }
    
    // Dummy data for course modules, replace with real data
    const courseModules = [
        {
            title: 'Modern HTML & CSS Syllabus', description: ['Course Introduction',
                'Application Development Life Cycle',
                'Front-End Dev vs Back-End Dev',
                'Project setup',
                'HTML Introduction',
                'Understand Tag Structure',
                'Understand Block, Inline & Empty Tags',
                'Formatting Tags',
                'HTML Table',
                'Web Fonts',
                'Images in HTML',
                'Forms in HTML',
                'iframes in HTML',
                'Video & Audio',
                'Box Shadows & Text Shadows',
                'Introduction to Flexbox',
                'Understand Flexbox',
                'Develop Webpage Layout using Flexbox',
                'Webpage using flexbox - Practice',
            ]
        },
        {
            title: 'Javascript', description: ['JavaScript - Introduction',
                'HELLO WORLD in JavaScript',
                'Basics of JavaScript',
                'External JavaScript',
                'Assignment 1',
                'JavaScript Logical Operators',
                'Assignment 2',
                'JavaScript Comparison Operators',
                'JavaScript Increment Operator',
                'JavaScript Number and String',
                'Concatenation',
                'Loops and iteration',
                'Assignment 3',
                'JavaScript Break and Continue',
                'Reserved words',
                'Assignment 4',]
        },
        {
            title: 'React.js', description: ["Application Development Life Cycle",
                "Front-End Development vs Back-End Development",
                "Project Setup",
                "Introduction to JSX",
                "Components in React",
                "Props and State Management",
                "Handling Events",
                "Conditional Rendering",
                "Lists and Keys",
                "Forms in React",
                "React Router",
                "Hooks",
                "Context API",
                "Redux",
                "Error Handling",
                "Performance Optimization",
                "Testing React Applications",
                "Deployment"]
        },
        {
            title: 'Node.js', description: ["Introduction to Node.js",
                "Asynchronous Programming in JavaScript",
                "Node.js Modules",
                "npm (Node Package Manager)",
                "Building a Basic HTTP Server",
                "Working with File System",
                "Event Emitters",
                "Streams and Buffers",
                "Express.js Framework",
                "RESTful APIs with Express.js",
                "Middleware in Express.js",
                "sequelize",
                "MongoDB/Postgresql",
                "Authentication and Authorization",
                "Error Handling in Node.js",
                "Unit Testing with Mocha and Chai",
                "Debugging Node.js Applications",
                "Performance Optimization",
                "Deployment Strategies"]
        },
    ];

    return (
        <>
        {
            enquiryForm && <EnquiryForm handleClose={handleClose} open={enquiryForm} handleDownload={handleDownload} />
        }
        <Box sx={{ p: 4, textAlign: 'center' }}>
            {/* <Grid container spacing={4} justifyContent="center" alignItems='center'> */}
            <Typography variant="h4" gutterBottom textAlign="center" sx={{ color: 'green', fontWeight: 'bold' }}>
                Curriculum
            </Typography>
            <Typography paragraph textAlign="center">

                Unleash your coding potential with Learnico's Full Stack Development Course! Master HTML, CSS, JavaScript, React, Node.js, and more with personalized support and daily follow-up calls. Get ready to code your way to success with placement support, internship opportunities, and lifetime job assistance. Level up your skills and launch your dream tech career today!
            </Typography>
            {/* <Typography sx={{ fontSize: '2rem', fontWeight: 'bold' }} >
                Live Classes with Mentor Support
            </Typography> */}
            <Divider sx={{ mb: 2 }} />
            <Grid container style={isMobile ? { paddingLeft: 0, paddingRight: 0 } : { paddingLeft: '5rem', paddingRight: '5rem' }}>
                <Grid container spacing={2} sx={{ mt: 0, mb: 4 }} >
                    {courseModules.map((module, index) => (
                        <Grid item xs={12} md={6} key={index} >
                            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} sx={{ border: '1px solid black' }}>
                                <AccordionSummary
                                    expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                >
                                    <Typography><b>{module.title}</b></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {module?.description?.map((desc, index) => (
                                        <Typography key={index} style={{ textAlign: 'left' }}>
                                            <span style={{ fontSize: '1.5em' }}>{'\u2022'}</span> {desc}
                                        </Typography>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            
                <Button variant="contained" sx={{ mb: 1, display: 'block', mx: 'auto', backgroundColor: '#f5a623', ':hover': { backgroundColor: 'blue' }, }}
                onClick={() => setEnquiryForm(true)}>
                
                    DOWNLOAD SYLLABUS BY TIMELINE
                </Button>
        </Box>
        </>
    );
};

export default CurriculumSection;
