import React from 'react';
import { Container, Typography, Paper, Box, List, ListItem, ListItemText } from '@mui/material';

const RefundPolicy = () => (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper elevation={3} sx={{ p: 3, mt: 3, overflow: 'auto' }}>
            <Typography paragraph style={{textAlign:'justify'}}>We want you to be satisfied, so all eligible courses purchased on Learnico can be refunded within 7 days. For whatever reason, if you are unhappy with a course, you can request a refund, provided the request meets the guidelines in our refund policy.</Typography>
            <Typography paragraph style={{textAlign:'justify'}}>Certain restrictions may apply and some purchases may only be eligible for credit refunds. For more information regarding our refund policy, please see below.</Typography>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
                Our 7-Day policy :
            </Typography>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bolder' }}>
                Additional Reasons for Denied Refunds :
            </Typography>
            <Typography paragraph>We reserve the right, in our sole discretion, to limit or deny refund requests in cases where we believe there is refund abuse, including but not limited to the following:</Typography>
            <List sx={{ listStyleType: 'disc', paddingLeft: 5 }}>
                <ListItem style={{ padding: 0 }} sx={{ display: 'list-item' }}><Typography paragraph>A significant portion of the course has been consumed or downloaded by a student before the refund was requested.</Typography></ListItem>
                <ListItem style={{ padding: 0 }} sx={{ display: 'list-item' }}><Typography paragraph>Multiple refunds have been requested by a student for the same course.</Typography></ListItem>
                <ListItem style={{ padding: 0 }} sx={{ display: 'list-item' }}><Typography paragraph>Excessive refunds have been requested by a student.</Typography></ListItem>
                <ListItem style={{ padding: 0 }} sx={{ display: 'list-item' }}><Typography paragraph>No refunds under any circumstances will be entertained if the delegate/participants/student attends 5 hours of the online session</Typography></ListItem>
            </List>
            <Typography paragraph>Users who have their account banned or course access disabled due to a violation of our Terms will not be eligible to receive a refund. We do not grant refunds for any subscription services after 7 days.</Typography>
            <Typography paragraph>These refund restrictions will be enforced to the extent permitted by applicable law.</Typography>
        </Paper>
    </Container>
);

export default RefundPolicy;
