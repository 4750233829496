// Banner.js
import { Box, Typography, Grid, Paper, TextField, Button } from '@mui/material';
import Image from '../asset/logo.png'

const NavBar = () => {
  return (
    <Grid
      container
      xs={12}
      sx={{
        background: '#FFFF',
        display: 'flex',
        alignItems: 'center',
        height: {xs: 30, md: 60},
        justifyContent: {xs: 'center', md: 'flex-start'},
        paddingLeft:{md: 15}
      }}
    >
      <Grid item
        xs={2}
        md={1}
      >
        <Box
          component="img"
          sx={{
            width: '100%',
          }}
          src={Image}
        />
      </Grid>
    </Grid>
  );
};
export default NavBar;