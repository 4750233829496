// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Example color
    },
    secondary: {
      main: '#dc004e', // Example color
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          // Custom Snackbar styles
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: { // Customize based on the severity
          backgroundColor: '#4caf50',
          color: 'white',
        },
        standardError: { // Customize based on the severity
          backgroundColor: '#f44336',
          color: 'white',
        },
        // Add more overrides for other severities as needed
      },
    },
  },
});

export default theme;
