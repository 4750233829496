import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Home'; // Your homepage component
import TermsOfServicePage from './components/TermsOfServicePage'; 
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
// Your Terms of Service component
import Footer from './components/Footer'; // Your Footer component
import './App.css'; // Adjust the path based on your file structure


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms-and-conditions" element={<TermsOfServicePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/refund-policy" element={<RefundPolicy/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
