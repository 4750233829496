import React from 'react';
import Slider from 'react-slick';
import { Box, Grid, Typography } from '@mui/material';
import { GetIsMobile } from '../util/general'
// Import the logos
import TCS from "../../src/asset/tcs_logo.png"
import Tech from "../../src/asset/tech_logo.png"
import INFY from "../../src/asset/INFY_logo.png"
import Accenture from "../../src/asset/accenture_logo.png"
import Wipro from "../../src/asset/wipro_logo.png"
import IBM from "../../src/asset/IBM_logo.png"

// Import the CSS for react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CompanyLogoSlider = () => {
    const isMobile = GetIsMobile();
    const arrayOfImages = [
        IBM,
        'https://www.guvi.in/images/webps/zen_new/tcl-logo-1.webp',
        'https://www.guvi.in/images/webps/zen_new/zoho-1.webp',
        TCS,
        'https://www.guvi.in/images/webps/zen_new/tata-communications.webp',
        'https://www.guvi.in/images/webps/zen_new/oneDPO-black-1-1-1920x662.webp',
        Tech,
        'https://www.guvi.in/images/webps/zen_new/orange.webp',
        'https://www.guvi.in/images/webps/zen_new/orcaso.webp',
        INFY,
        'https://www.guvi.in/images/webps/zen_new/searchpng.com-flipkart-logo-png-image-free-download.webp',
        'https://www.guvi.in/images/webps/zen_new/chargebee.webp',
        Accenture,
        Wipro,
        'https://www.guvi.in/images/webps/zen_new/clayfin-logo-large.webp',
        'https://www.guvi.in/images/webps/zen_new/juspay.webp',
        INFY,
    ]
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        arrows: false,
        responsive: isMobile && [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };


    return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
            <Grid container spacing={4} justifyContent="center" alignItems='center'>
                <Typography sx={{ padding: 5, textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}> Student’s working at</Typography>
                <div style={{ margin: '0 auto', padding: '40px', width: '95%', position: 'relative', overflow: 'hidden' }}>
                    <Slider {...settings}>
                        {arrayOfImages.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt="Company Logo"
                                    style={{
                                        height: '70px', // Increase height for mobile view
                                        width: '145px', // Adjust width as necessary
                                        objectFit: 'contain'
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Grid>
        </Box>
    );
};

export default CompanyLogoSlider;
