import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import Image from '../asset/company.png';
import Photo from '../asset/Learnico - SM02.png';

const SectionOne = (props) => {
  const { setRefState } = props;
  return (
    <Grid container sx={{
      background: 'linear-gradient(to right bottom, #002970, #3A6AB9)',
      height: { xs: 'calc(100vh + 350px)', md: 'calc(100vh - 60px)' },

    }}>
      <Grid item container xs={12}
        sx={{
          direction: 'row',
          padding: 5,
        }}
      >
        <Grid item xs={0} md={2}></Grid>
        <Grid item container md={5}>
          <Grid item xs={12}>
            {/* <Box
              sx={{
                margin: '8px 0px 12px 0px',
                maxWidth: { xs: '50%', md: '25%' }
              }}
            >
              <img
                srcSet={Image}
                src={Image}
                alt='company'
                loading="lazy"
                style={{
                  maxWidth: '100%'
                }}
              />
            </Box> */}
            <Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: { xs: '28px', md: '36px' },
                  fontWeight: 700,
                  color: '#FFFF',
                  margin: '0px 0px 8px 0px'
                }}
              >
                Master web development in just 100 days
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: '22px', md: '24px' },
                  fontWeight: 600,
                  color: 'rgba(245,166,35,1)',
                  margin: '0px 0px 12px 0px'
                }}
              >
                Grow 10X Web Development Pack
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '18px',
                  // fontSize: {xs: '16px', md: '18px'},
                  fontWeight: 400,
                  color: '#FFFF',
                  margin: '0px 0px 8px 0px',
                  textAlign: 'left'
                }}
              >
                Unlock your potential with Learnico's Full Stack Development Course! Dive deep into HTML, CSS, JavaScript, React, Node.js, and more, with personalized mentorship and daily follow-up calls. Gain hands-on experience, access exclusive internship opportunities, and get 100% Job guarantee for a successful tech career. Start coding your future today!
              </Typography>

            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#FFFF',
                  margin: '0px 0px 8px 0px',
                  textAlign: 'left'
                }}
              >
                Mode: 100% Online
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                  color: '#FFFF',
                  margin: '0px 0px 8px 0px',
                  textAlign: 'left'
                }}
              >
                Enroll Now and Get Flat 30% OFF!
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontSize: '36px',
                    fontWeight: 700,
                    color: '#FFFF',
                    margin: '0px 0px 8px 0px',
                    textAlign: 'left'
                  }}
                >
                  <span style={{ textDecoration: 'line-through', color: '#FFFF' }}>₹69999</span> {/* Use a span for the part of the text you want to strikethrough */}
                  {' '} {/* Add a space between the two prices */}
                  <span style={{ color: '#f5a623' }}>₹49999</span> {/* Another span for the new price with a different color */}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
              <Box sx={{ p: 0 }}>
                <Button variant="contained" sx={{ backgroundColor: '#f5a623', ':hover': { backgroundColor: 'transparent' }, padding: '10px 30px 10px 30px', width: { xs: '100%', md: 'auto' } }} onClick={() => {
                  setRefState(true)
                }}>
                  <Typography textTransform='none' variant="button" sx={{ color: '#FFFF', fontSize: '16px', fontWeight: 600 }}>Enroll</Typography>
                </Button>
              </Box>
              {/* <Box sx={{ pl: { md: 2 } }}>
                <Button variant="contained" sx={{ backgroundColor: 'transparent', ':hover': { backgroundColor: 'transparent' }, padding: '10px 30px 10px 30px', border: '1px solid', width: { xs: '100%', md: 'auto' } }}>
                  <Typography textTransform='none' variant="button" sx={{ color: '#FFFF', fontSize: '16px', fontWeight: 600, }}>View Syllabus</Typography>
                </Button>
              </Box> */}
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#FFFF',
                  margin: '16px 0px 8px 0px'
                }}
              >
                With 7 days refund policy*
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center', // Centers the image horizontally in the Box
            alignItems: 'center', // Centers the image vertically in the Box
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end', // Centers the image horizontally in the Box
              alignItems: 'center', // Centers the image vertically in the Box
              overflow: 'hidden', // Prevents the image from overflowing the Box's boundaries
              height: 400, // Or any value that fits your design
              width: '100%', // Takes the full width of the Grid item; adjust as needed
            }}
          >
            <img
              srcSet={Photo}
              src={Photo}
              alt="company"
              loading="lazy"
              style={{
                maxWidth: '100%', // Ensures the image is fully contained within the Box width
                maxHeight: '100%', // Ensures the image is fully contained within the Box height
                // Remove width and height styles if using maxWidth and maxHeight for responsiveness
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionOne;
